var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.randomizedOptions)?_c('div',{staticClass:"full-area",class:{'feedback': _vm.feedback}},[_c('FeedbackScreen',{attrs:{"data":_vm.data,"feedback":_vm.feedback},on:{"retry":function($event){return _vm.retry()},"next":function($event){return _vm.clearAndGo()}}}),_c('QuestionHeader',{attrs:{"data":_vm.data}}),_c('content',{staticClass:"content",class:{
      'align-top': _vm.randomizedOptions.length >= 4,
      'three-rows': _vm.randomizedOptions.length > 4
    }},[_c('div',{key:_vm.randomizedOptions.join('-.'),staticClass:"grid-wrapper delay-entry"},_vm._l((_vm.randomizedOptions),function(box,key){return _c('div',{staticClass:"binary-box delay-entry",class:{
          'standoutx': _vm.conjointItem.selected === box,
          'large-portrait': _vm.data.fields.largePortrait,
          'display-selection-order': _vm.data.fields.displaySelectionOrder,
          'display-text-options': _vm.data.fields.textOptions
        },attrs:{"span":12},on:{"click":function($event){return _vm.toggleThis(box)}}},[(!_vm.data.fields.displaySelectionOrder)?_c('i',{staticClass:"el-icon-check icon-inside"}):_vm._e(),(_vm.data.fields.displaySelectionOrder && _vm.getPeckingOrder(box))?_c('div',{staticClass:"selection-orderx icon-inside"},[_vm._v(_vm._s(_vm.getPeckingOrder(box)))]):_vm._e(),_c('span',{staticClass:"rendered-image-title large-label",domProps:{"innerHTML":_vm._s(_vm.getRichText(box))}})])}),0)]),_c('SkipQuestionButton',{key:_vm.randomizedOptions.join('x'),attrs:{"show-submit":false,"skip-text":_vm.data.fields.altOption},on:{"skip":function($event){return _vm.skipConjoint()}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }