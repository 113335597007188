<template>
  <div class="full-area" :class="{'feedback': feedback}" v-if="randomizedOptions">
    <FeedbackScreen
      :data="data"
      :feedback="feedback"
      v-on:retry="retry()"
      v-on:next="clearAndGo()"
    ></FeedbackScreen>

    <QuestionHeader
      :data="data"
    ></QuestionHeader>

    <content class="content"
      :class="{
        'align-top': randomizedOptions.length >= 4,
        'three-rows': randomizedOptions.length > 4
      }"
    >
      <div class="grid-wrapper delay-entry" :key="randomizedOptions.join('-.')">
        <div
          :span="12"
          v-for="(box, key) in randomizedOptions"
          class="binary-box delay-entry"
          :class="{
            'standoutx': conjointItem.selected === box,
            'large-portrait': data.fields.largePortrait,
            'display-selection-order': data.fields.displaySelectionOrder,
            'display-text-options': data.fields.textOptions
          }"
          @click="toggleThis(box)"
        >
          <i class="el-icon-check icon-inside" v-if="!data.fields.displaySelectionOrder"></i>
          <div class="selection-orderx icon-inside" v-if="data.fields.displaySelectionOrder && getPeckingOrder(box)">{{getPeckingOrder(box)}}</div>
          <span class="rendered-image-title large-label" v-html="getRichText(box)"></span>
        </div>
      </div>
    </content>

    <SkipQuestionButton
      :show-submit="false"
      v-on:skip="skipConjoint()"
      :key="randomizedOptions.join('x')"
      :skip-text="data.fields.altOption"
    ></SkipQuestionButton>
  </div>
</template>

<script>
export default {
  name: 'Conjoint',

  props: [ 'data' ],

  components: {
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      maxAttempts: 20,
      attempt: 0,
      feedback: '',
      locked: false,
      randomizedFormula: [],
      randomizedOptions: [],
      randomizedLabels: [],
      randomizedSkip: '',
      howManyRounds: this.data.fields.rounds || 1,
      howManyCards: this.data.fields.howManyCards || 2,
      iterations: 0,
      timestamp: '',
      optionsIndexes: [],
      conjointItem: {
        options: [],
        selected: '',
        selectedIndex: -1,
        optionsIndexes: [],
        timeToCompletion: null
      },
      form: {
        type: `conjoint`,
        identifier: this.data.fields.identifier,
        question: this.data.fields.title,
        questionLength: this.data.fields.title.length,
        numberOfOptions: this.data.fields.textOptions.length,
        id: this.data.sys.id,
        selected: [],
        labelSelection: [],
        splitSelected: {},
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      }
    }
  },

  computed: {
    validAnswers () {
      return 1
    },
    inputIsValid () {
      if (this.data.fields.displaySelectionOrder) {
        if (this.form.selected.length === this.validAnswers) {
          return true
        } else {
          return false
        }
      }
      if (this.form.selected.length >= 1 && this.form.selected.length <= this.validAnswers) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    getRichText (text) {
      return text.replace(/{{br}}/g, '<br>')
    },
    getBoxLabel (label) {
      if (label.indexOf('...') === 0) {
        return label.substring(3)
      }
      return label
    },
    randomize () {
      this.attempt++
      if (this.attempt > this.maxAttempts) {
        this.skipConjoint()
        return
      }
      this.timestamp = new Date().getTime()

      this.randomizedOptions = this.shuffle(this.data.fields.textOptions.slice()).slice(0, this.howManyCards)

      var optionsIndexes = []
      this.conjointItem.selected = ''
      this.randomizedOptions.forEach(option => {
        optionsIndexes.push(this.data.fields.textOptions.findIndex(item => item === option))
      })

      this.optionsIndexes = optionsIndexes
      // check that this pair hasn't been drawn before
      var pairWasDrawn = this.$store.state.conjoint.some(a => 
        a.optionsIndexes.slice().sort((a, b) => { return a - b }).join('-') === optionsIndexes.slice().sort((a, b) => { return a - b }).join('-')
      )
      if (pairWasDrawn) {
        setTimeout(() => {
          this.randomize()
        }, 10)
      }
      setTimeout(() => {
        this.locked = false        
      }, 800)
      return
    },
    shuffle (array) {
      var currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
    },
    skipConjoint () {
      this.toggleThis('S99')
    },
    retry () {
      this.form.selected = []
      this.locked = false
      this.feedback = ''
    },
    clearAndGo () {
      this.feedback = ''
      this.emitNext()
    },
    emitNext () {
      if (!this.feedback) {
        this.$emit('next', this.form)
      }
    },
    skipQuestion () {
      this.form.selected = ['S99']
      this.next()
    },
    submitQuestion () {
      this.next()
    },
    toggleThis (item) {
      if (this.locked) return
      var conjointItem = {
        options: this.randomizedOptions,
        selected: item,
        timeToCompletion: new Date().getTime() - this.timestamp,
        optionsIndexes: this.optionsIndexes,
        selectedIndex: this.data.fields.textOptions.findIndex(option => option === item)
      }
      this.conjointItem.selected = item
      this.iterations = this.iterations + 1
      this.form.timeToCompletion = this.form.timeToCompletion + new Date().getTime() - this.timestamp
      this.$store.commit('STORE_NEW_CONJOINT', { conjoint: conjointItem })
      this.locked = true
      if (this.howManyRounds === this.iterations) {
        setTimeout(() => {
          this.next()
        }, 50)
      } else {
        setTimeout(() => {
          this.randomize()
        }, 500)
      }
    },
    next () {
      this.emitNext()
    }
  },

  mounted () {
    this.randomize()
  }
}
</script>

<style scoped lang="scss">
.icon-inside.selection-orderx {
  bottom: unset;
  top: 5px;
}
.large-label {
  height: 94% !important;
  width: 94% !important;
  padding: 3% !important;
  font-size: 20px !important;
  line-height: 1.1 !important;
  display: flex !important;
  border-radius: 5px !important;
  border: none;
  justify-content: center !important;
  align-items: center !important;
  background-color: transparent !important;
  color: $text-main !important;
}
.display-text-options {
  border: 1px solid rgba($gray-medium, 0.2) !important;
}
.binary-box.standoutx.display-selection-order {
  transform: scale(1.02) !important;
}
.content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 20px;

  @include breakpoint($micro) {
    margin-bottom: 30px;
  }
  @include breakpoint($desktop) {
    align-items: flex-start;
    margin-bottom: 0;
  }
  &.align-top {
    @include breakpoint($desktop) {
      align-items: flex-start;
    }
  }
}

.binary-box {
  opacity: 0.9;
  transform: scale(1);
  transition: opacity ease-in-out 0.2s, transform 0.2s ease-in-out;
  position: relative;
  border: 1px solid $gray-light !important;

  &.standoutx {
    animation: standOut 0.4s ease-in-out;
    opacity: 1;
    transform: scale(1.05);
    z-index: 100;

    .icon-inside {
      transform: scale(1);
      opacity: 1;
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    }
  }
}
.content.three-rows {
  @include breakpointHeight($square) {
    transform: scale(0.95);
  }
  .binary-box {

    @include breakpoint($micro) {
      width: 40%;
      padding-bottom: calc(40% * 3/4);
      margin-bottom: calc(calc(100% - (40% * 2)) / 6);
      margin-top: calc(calc(100% - (40% * 2)) / 6);
    }
    @include breakpointHeight($square) {
      width: 40%;
      padding-bottom: calc(40% * 3/4);
      margin-bottom: calc(calc(100% - (40% * 2)) / 16);
      margin-top: calc(calc(100% - (40% * 2)) / 16);
    }
  }
}
.icon-inside {
  opacity: 0.0001;
  transform: scale(0.9);
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  background: $color-emphasis-alt;
  border: 2px solid white;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.render-image-wrapper {
  border: 1px solid rgba($gray-medium, 0.4);
  background-position-y: top;
}

.rendered-image-title {
  z-index: -1;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 0.8em;
  background: rgba($color-admin-emphasis, 0.8);
  color: white;
}

</style>
